<template>
  <div>
    <tab-headers class="mb-5"> Manage Account </tab-headers>
    <div class="">
      <a-input
        v-model="searchKeyword"
        placeholder="Search..."
        @change="debounceSearch"
      ></a-input>
    </div>
    <div class="mt-5 bg-white shadow rounded p-2">
      <a-table :loading="gettingUser" :columns="headers" :data-source="users">
        <span slot="action" slot-scope="record">
          <a-button @click="showDrawer(record)">View </a-button>
        </span>
        <span slot="status" slot-scope="record">
          <div
            :class="`
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`"
          >
            {{ record.status }}
          </div>
        </span>
        <span slot="verified" slot-scope="record">
          <div
            :class="`
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`"
          >
            {{ record.isVerified ? 'Verified' : 'Not Verified' }}
          </div>
        </span>
      </a-table>
    </div>
    <a-drawer
      width="450"
      placement="right"
      closable
      :visible="visible"
      @close="onClose"
    >
      <a-avatar :size="105" :src="user.avatar" />
      <h3 class="text-2xl capitalize mb-5">{{ user.lastName }}'s Profile</h3>
      <div class="grid grid-cols-2">
        <div>
          <p class="mb-0">First Name</p>
          <p class="font-semibold">{{ user.firstName }}</p>
        </div>
        <div>
          <p class="mb-0">Last Name</p>
          <p class="font-semibold">{{ user.lastName }}</p>
        </div>

        <div>
          <p class="mb-0">Registered On</p>
          <p class="font-semibold">
            {{ new Date(user.createdAt).toDateString() }}
          </p>
        </div>

        <div>
          <p class="mb-0">Phone</p>
          <p class="font-semibold">
            {{ user.phone }}
          </p>
        </div>
        <div class="col-span-2">
          <p class="mb-0">Email</p>
          <p class="font-semibold">{{ user.email }}</p>
        </div>
        <div>
          <p class="mb-0">State</p>
          <p class="font-semibold">
            {{ user.state ? user.state.title : '' }}
          </p>
        </div>
        <div>
          <p class="mb-0">Town</p>
          <p class="font-semibold">
            {{ user.town ? user.town.title : '' }}
          </p>
        </div>

        <div>
          <p class="mb-0">Gender</p>
          <p class="font-semibold">{{ user.gender }}</p>
        </div>

        <div>
          <p class="mb-0">Business Address</p>
          <p class="font-semibold">{{ user.businessAddress }}</p>
        </div>
      </div>
      <div>
        <a-button
          :style="{ marginRight: '8px' }"
          :disabled="actionOnUserLoading"
          @click="onClose"
        >
          Cancel
        </a-button>
        <a-button
          type="error"
          :disabled="user.status === 'suspended' || actionOnUserLoading"
          :loading="actionOnUserLoading"
          @click="actionOnUser('suspended')"
        >
          Suspend
        </a-button>
        <a-button
          type="primary"
          :disabled="user.status === 'active' || actionOnUserLoading"
          @click="actionOnUser('active')"
        >
          Activate
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import _ from 'lodash'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import usersApi from '../../api/users'

const headers = [
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

const data = []

export default {
  name: 'UserManagement',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      headers,
      data,
      gettingUser: false,
      searchKeyword: '',
      users: [],
      user: {},
      visible: false,
      actionOnUserLoading: false,
      pagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0,
      },
    }
  },
  created() {
    this.getAllUsers(this.pagination)
    this.debounceSearch = _.debounce(this.searchUser, 500)
  },
  methods: {
    async searchUser() {
      if (this.searchKeyword === '') {
        this.getAllUsers(this.pagination)
        return
      }
      this.gettingUser = true
      const req = await usersApi(this.axios).getAllUser(
        { role: 'user' },
        {
          keyword: this.searchKeyword,
          limit: 0,
          offset: 0,
          order: 'asc',
          orderBy: 'lastName',
        }
      )
      this.users = req.data.data
      this.gettingUser = false
    },
    // eslint-disable-next-line no-unused-vars
    async getAllUsers(pagination) {
      this.gettingUser = true
      const req = await usersApi(this.axios).getAllUser(
        { role: 'user' },
        {
          order: 'asc',
          orderBy: 'lastName',
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        }
      )
      this.users = req.data.data
      this.pagination = { ...this.pagination, ...req.data.meta }
      this.pagination.page =
        Math.ceil(this.pagination.total / this.pagination.limit) === Infinity
          ? 0
          : Math.ceil(this.pagination.total / this.pagination.limit)
      this.gettingUser = false
    },
    async actionOnUser(action) {
      this.actionOnUserLoading = true
      const req = await usersApi(this.axios).updateUserStatus({
        userId: this.user._id,
        status: action,
      })
      if (req.error) {
        this.$store.dispatch('notification/updateNotification', {
          type: 'error',
          message: req.message,
        })
        this.actionOnUserLoading = false
        return
      }
      this.$store.dispatch('notification/updateNotification', {
        type: 'success',
        title: 'Successful',
        message: req.message,
      })
      this.actionOnUserLoading = false
      this.getAllUsers()
      this.onClose()
    },
    showDrawer(user) {
      this.user = user
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.user = {}
    },
  },
}
</script>
